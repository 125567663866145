<template>
<div class="prime base-slider">
    <article class="slide" v-for="slide in slidebar" :key="slide.id">
        <img :src="urlPath+'uploads/media/slider/'+slide.image" alt="image description" class="stem">
        <div class="container">
            <div class="prime__caption">
                <h1 v-text="slide.title"></h1>
                <p v-html="slide.description"></p>
                <a v-if="slide.ctalink" :href="'//'+slide.ctalink" class="btn btn-primary">Know More</a>
            </div>
        </div>
    </article>
    
</div>
</template>

<script>
import axios from 'axios';
import jQuery from 'jquery';
export default {

    name: 'Mainbanner',
    data() {
        return {
            slidebar: undefined,
            apiPath:process.env.VUE_APP_API,
            urlPath:process.env.VUE_APP_PATH,
        }
    },
    async updated(){
         jQuery('.base-slider').slick({
            mobileFirst: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            autoplay: true,
            autoplaySpeed: 5000
        });
    },
    async mounted() {
        this.getslides();
        
        
        

    },
    methods: {
        getslides() {
            axios.get(this.apiPath+'callslider')
                .then((response) => {
                    this.slidebar = response.data.data;
                })
        },
    },
}
</script>
